import React, { useEffect, useRef, useState } from "react";
// import MainLogo from "./MainLogo";
import { useParams } from "react-router-dom";
// import {
//   EmailShareButton,
//   FacebookShareButton,
//   LinkedinShareButton,
//   TwitterShareButton,
// } from "react-share";
import html2canvas from "html2canvas";
// import { SuccessToast } from "../helper/Toast";
// import roleContext from "../contexts/roleContext.js";
import { API_Path } from "../API/ApiComment.js";
import { ApiGet } from "../API/API_data.js";
import loader from "../assets/videos/loader.mp4";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { motion } from "framer-motion";
// import {
//   FaFacebookF,
//   FaInstagram,
//   FaLinkedinIn,
//   FaWhatsapp,
// } from "react-icons/fa";
// import { AiFillTwitterCircle } from "react-icons/ai";
// import { MdOutlineMail } from "react-icons/md";
import { IoClose, IoShareSocialSharp } from "react-icons/io5";
import PopTileCard from "./PopTileCard.js";
import Footer from "./Footer.js";
import { SuccessToast } from "../helper/Toast.js";

const Card = ({ data }) => {
  return (
    <div className="flex flex-col justify-center items-center border border-black bg-white w-fit">
      <div className="flex relative sm:flex-col gap-4 sm:gap-0">
        <div className="bg-white p-3">
          <img
            src={data?.imageLink}
            alt="tileimage"
            className="w-[200px] xxs:w-[150px]"
          />
        </div>
        <div className="w-5 sm:p-2 sm:text-center sm:w-full relative sm:mb-2">
          <h1 className="text-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[20px] md:text-[16px] font-playfair font-semibold text-[#383838] transform rotate-90 origin-center text-nowrap sm:rotate-0">
            {data?.finalTitle}
          </h1>
        </div>
      </div>
      <div className="max-w-[380px] text-[#383838] text-xs pb-3 sm:px-2 px-3">
        <p className="">{data?.finalDesc1}</p>
        <p className="pt-2 md:pt-1">{data?.finalDesc2}</p>
        <p className="pt-2 md:pt-1">{data?.finalDesc3}</p>
      </div>
    </div>
  );
};

const TileCard = ({ data }) => {
  return (
    <div className="flex flex-col justify-center items-center border border-black bg-white w-fit">
      <div className="pt-4 3xl:pt-2 flex relative sm:flex-col gap-4 sm:gap-0">
        <div className="bg-white p-3 3xl:p-2">
          <img
            src={data?.base64Image || data?.imageLink}
            alt="tileimage"
            className="w-[200px] 3xl:w-[160px] xxs:w-[150px]"
          />
        </div>
        <div className="w-5 sm:p-2 sm:text-center sm:w-full relative sm:mb-2">
          <h1 className="text-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[20px] 3xl:text-[16px] md:text-[16px] font-playfair font-semibold text-[#383838] transform rotate-90 origin-center text-nowrap sm:rotate-0">
            {data?.finalTitle}
          </h1>
        </div>
      </div>
      <div className="max-w-[380px] text-[#383838] text-xs pb-5 sm:px-2 px-5 3xl:px-3">
        <p className="pt-2">{data?.finalDesc1}</p>
        <p className="pt-3 md:pt-2">{data?.finalDesc2}</p>
        <p className="pt-3 md:pt-2">{data?.finalDesc3}</p>
      </div>
    </div>
  );
};

const SubmitPage = () => {
  // const location = useLocation();
  const { id } = useParams();
  const logoRef = useRef();
  // const context = useContext(roleContext);
  const [data, setData] = useState([]);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const [showCard, setShowCard] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [countdown, setCountdown] = useState(20); //20
  const [cardData, setCardData] = useState([]);
  const [startAnimation, setStartAnimation] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(12);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const handleClose = () => setShowPopup(false);

  const openModal = (data) => {
    setModalData(data);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const handleOverlayClick = (e) => {
    if (e.target.id === "modal-overlay") {
      closeModal();
    }
  };
  const handlePopUpClick = (e) => {
    if (e.target.id === "popup-overlay") {
      handleClose();
    }
  };

  const shareUrl = "https://demo4.outdoinc.com";
  const title =
    "I just discovered my unique caregiving style! 🧡 Check out my tile in the @noorahealth #CaregiverCommunityMosaic. Want to find yours? Take the quiz! #CaregiverCommunityMosaic #Anniversary hashtag tbd";

  // const content =
  //   "I just discovered my unique caregiving style! 🧡 Check out my tile in the @noorahealth #CaregiverCommunityMosaic. Want to find yours? Take the quiz! #CaregiverCommunityMosaic #Anniversary hashtag tbd @NooraHealth";

  useEffect(() => {
    getTileData(id);
    getCardData();
  }, [id]);

  useEffect(() => {
    if (isVideoPlaying) {
      const videoDuration = 4000; //4000
      setTimeout(() => {
        setIsVideoPlaying(false);
        setShowCard(true);
      }, videoDuration);
    }
  }, [isVideoPlaying]);

  useEffect(() => {
    if (showCard) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            setShowCard(false);
            setIsContentVisible(true);
            setStartAnimation(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [showCard]);

  const getTileData = async (id) => {
    try {
      const res = await ApiGet(API_Path.submitUrl(id));
      if (res?.success === true) {
        setData(res?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getCardData = async () => {
    try {
      const res = await ApiGet(API_Path.tileDataUrl);
      if (res?.success === true) {
        setCardData(res?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const downloadLogo = () => {
    if (logoRef.current) {
      html2canvas(logoRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "mytile.png";
        link.click();
      });
    }
  };

  const updateItemsToShow = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 640) {
      setItemsToShow(12);
    } else if (screenWidth < 1024) {
      setItemsToShow(34);
    } else {
      setItemsToShow(48);
    }
  };

  useEffect(() => {
    updateItemsToShow(); // Initial call
    window.addEventListener("resize", updateItemsToShow);
    return () => window.removeEventListener("resize", updateItemsToShow);
  }, []);

  const displayData = () => {
    // If cardData length exceeds the number of items to show
    if (cardData?.length > itemsToShow) {
      // Create a copy of the data to avoid mutating the original
      let dataCopy = [...cardData];

      // Remove first 17 items until the length is less than or equal to itemsToShow
      while (dataCopy?.length > itemsToShow) {
        dataCopy?.splice(0, 17); // Remove the first 17 items
      }

      return dataCopy; // Return the modified array
    }

    return cardData; // Return all data if length is less than or equal to itemsToShow
  };

  const processedData = displayData();

  // const onShare = async (platform) => {
  //   try {
  //     const textToCopy = `${title}\n${shareUrl}`;
  //     await navigator.clipboard.writeText(textToCopy);
  //     SuccessToast("Content copied to clipboard");

  //     const isMobile =
  //       /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //         navigator.userAgent
  //       );
  //     if (isMobile) {
  //     if (!logoRef.current) {
  //       console.error("logoRef not found");
  //       return;
  //     }

  //     const canvas = await html2canvas(logoRef.current);
  //     const dataUrl = canvas.toDataURL("image/png");

  //     const response = await fetch(dataUrl);
  //     const blob = await response.blob();
  //     const file = new File([blob], "TileCard.png", { type: "image/png" });

  //     const shareData = {
  //       text: title,
  //       url: shareUrl,
  //       files: [file],
  //     };

  //     if (navigator.canShare && navigator.canShare(shareData)) {
  //       await navigator.share(shareData);
  //       console.log("Shared successfully");
  //       return;
  //     }
  //     }

  //     const encodedText = encodeURIComponent(
  //       platform === "twitter" ? content : title
  //     );
  //     const encodedContent = encodeURIComponent(content);
  //     const encodedUrl = encodeURIComponent(shareUrl);

  //     switch (platform) {
  //       case "whatsapp": {
  //         const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedText}%0A%0A${encodedUrl}`;
  //         window.open(whatsappUrl, "_blank");
  //         break;
  //       }
  //       case "facebook": {
  //         const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${encodedContent}`;
  //         window.open(facebookUrl, "_blank");
  //         break;
  //       }
  //       case "twitter": {
  //         const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedContent}`;
  //         window.open(twitterUrl, "_blank");
  //         break;
  //       }
  //       case "instagram": {
  //         const instagramUrl = `https://www.instagram.com/accounts/login/`;
  //         window.open(instagramUrl, "_blank");
  //         break;
  //       }
  //       case "linkedin": {
  //         const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&summary=${encodedContent}`;
  //         window.open(linkedinUrl, "_blank");
  //         break;
  //       }
  //       case "email": {
  //         const emailSubject = encodeURIComponent(`Check this out: ${title}`);
  //         const emailBody = encodeURIComponent(
  //           `${encodedText}\n\n${encodedUrl}`
  //         );
  //         const mailtoUrl = `mailto:?subject=${emailSubject}&body=${emailBody}`;
  //         window.open(mailtoUrl, "_blank");
  //         break;
  //       }
  //       default:
  //         console.error("Unsupported platform");
  //     }
  //   } catch (error) {
  //     console.error("Error sharing:", error);
  //   }
  // };

  const copyClipboard = () => {
    const textToCopy = `${title}\n${shareUrl}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Text copied to clipboard");
        SuccessToast("Caption copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const onShare = () => {
    setShowPopup(true);
    copyClipboard();
    downloadLogo();
  };

  return (
    <>
      <div className="bg-[#fdfaf7]">
        {isVideoPlaying && (
          <div className="flex items-center justify-center bg-[#fdfaf7] h-[calc(100vh-73px)] md:h-dvh md:overflow-auto">
            <video
              src={loader}
              autoPlay
              muted
              className="w-20 h-3w-20 object-cover"
              onEnded={() => setIsVideoPlaying(false)}
            />
          </div>
        )}
        {showCard && !isContentVisible && (
          <div
            className="flex flex-col justify-center items-center h-[calc(100vh-73px)] md:h-dvh md:overflow-auto gap-2"
            style={{
              backgroundImage:
                "url(https://sapient-dev-bucket.s3.ap-south-1.amazonaws.com/dental-web-app/uploads/45462649295340121596.final-bg.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100vw",
            }}
          >
            <div className="flex flex-col justify-center items-center h-[calc(100vh-73px)] md:h-dvh md:overflow-auto">
              <div className="text-center text-xl md:text-[16px] text-[#383838]">
                <h1> Your caregiving style is</h1>
                <h1 className="font-semibold">{data?.finalTitle}</h1>
              </div>
              <div className="flex justify-center items-center md:mx-5 my-2">
                <Card data={data} />
              </div>
              <div className="max-w-60 md:text-[14px]">
                Your tile is being added to our Caregiver Community Mosaic
              </div>
              <div
                className={`flex items-center justify-center rounded-full w-12 h-12 md:w-8 md:h-8 border border-black`}
              >
                <span className="text-2xl md:text-[16px] font-semibold">
                  {countdown}
                </span>
              </div>
            </div>
          </div>
        )}
        {isContentVisible && (
          <div className="p-6 lg:p-5 h-[calc(100vh-73px)] md:h-dvh overflow-auto">
            <div className="flex lg:flex-col gap-4 lg:gap-0 bg-[#fdfaf7]">
              <div className="w-full">
                <h1 className="font-playfair text-2xl text-center mb-6">
                  Caregiver Community Mosaic
                </h1>
                <div className="flex flex-wrap gap-2 lg:justify-center">
                  {processedData?.length > 0 && (
                    <>
                      {processedData?.slice(0, -1).map((val, index) => (
                        <img
                          key={index}
                          src={val?.imageLink}
                          alt={`Logo ${index}`}
                          className="w-20 h-20 rounded-md cursor-pointer border border-gray-300 p-2 bg-white flex justify-center items-center"
                          onClick={() => openModal(val)}
                        />
                      ))}
                      <motion.img
                        key={processedData.length - 1}
                        src={processedData[processedData.length - 1]?.imageLink}
                        alt={`Logo ${processedData.length - 1}`}
                        className="w-20 h-20 rounded-md cursor-pointer border border-[#383838] p-2 bg-white flex justify-center items-center"
                        initial={{ y: 300, opacity: 0 }}
                        animate={startAnimation ? { y: 0, opacity: 1 } : {}}
                        transition={{ duration: 3 }}
                        onClick={() =>
                          openModal(processedData[processedData.length - 1])
                        }
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="min-w-[350px] md:min-w-min lg:py-5">
                <h1 className="font-playfair text-2xl text-center mb-6">
                  Your Tile
                </h1>
                <div className="flex justify-center items-center" ref={logoRef}>
                  <TileCard data={data} />
                </div>
                <div className="flex justify-center gap-2 mt-4">
                  {/* <div
                    onClick={() => onShare("whatsapp")}
                    className="cursor-pointer"
                  >
                    <div className="bg-black p-2 rounded-full">
                      <FaWhatsapp className="text-white" size={20} />
                    </div>
                  </div>
                  <div
                    onClick={() => onShare("facebook")}
                    className="cursor-pointer"
                  >
                    <div className="bg-black p-2 rounded-full">
                      <FaFacebookF className="text-white" size={20} />
                    </div>
                  </div>

                  <div
                    onClick={() => onShare("twitter")}
                    className="cursor-pointer"
                  >
                    <AiFillTwitterCircle size={40} />
                  </div>

                  <div
                    onClick={() => onShare("instagram")}
                    className="cursor-pointer"
                  >
                    <div className="bg-black p-2 rounded-full">
                      <FaInstagram className="text-white" size={20} />
                    </div>
                  </div>

                  <div
                    onClick={() => onShare("linkedin")}
                    className="cursor-pointer"
                  >
                    <div className="bg-black p-2 rounded-full">
                      <FaLinkedinIn className="text-white" size={20} />
                    </div>
                  </div> */}

                  <div onClick={() => onShare()} className="cursor-pointer">
                    <div className="bg-black p-2 rounded-full">
                      <IoShareSocialSharp className="text-white" size={20} />
                    </div>
                  </div>

                  {/* <div
                    onClick={() => onShare("email")}
                    className="cursor-pointer"
                  >
                    <EmailShareButton url={shareUrl} quote={title}>
                    <div className="bg-black p-2 rounded-full">
                      <MdOutlineMail className="text-white" size={20} />
                    </div>
                    </EmailShareButton>
                  </div> */}

                  <div onClick={downloadLogo} className="cursor-pointer">
                    <PiDownloadSimpleBold className="text-[36px] font-bold" />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <h1 className="text-xl text-center my-4 max-w-40 2xl:max-w-max">
                    Thank you for being a part of our community
                  </h1>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div
          id="modal-overlay"
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 px-5"
          onClick={handleOverlayClick}
        >
          <div className="bg-[#fdfaf7] p-4 rounded-md w-96 shadow-lg">
            <button
              className="ml-auto mb-2 text-gray-500 hover:text-gray-700 flex justify-end"
              onClick={closeModal}
            >
              <IoClose size={22} />
            </button>
            {modalData && <PopTileCard data={modalData} />}
          </div>
        </div>
      )}

      {showPopup && (
        <div
          id="popup-overlay"
          className="fixed inset-0 z-20 flex items-center justify-center bg-gray-900 bg-opacity-60 transition-opacity duration-300 py-5"
          onClick={handlePopUpClick}
        >
          <div className="relative bg-white p-6 rounded-lg shadow-2xl animate-fadeIn max-h-[calc(100vh-60px)] overflow-auto max-w-md w-10/12">
            <button
              className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 transition duration-200"
              onClick={handleClose}
            >
              <IoClose size={24} />
            </button>
            <h2 className="text-xl font-semibold text-center text-gray-800 mb-4">
              🎉 Share Your Tile!
            </h2>
            <p className="text-center text-gray-600 mb-4">
              Your tile has been downloaded to your device.
            </p>
            <div className="text-center mb-4">
              <p className="text-gray-700">
                Open the Social Media platform of your choice to share your tile
                and paste this caption:
              </p>
              <div className="border-2 border-dashed border-gray-300 p-3 rounded-lg bg-gray-50 mt-3">
                <span className="font-semibold xxs:break-all">{title}</span>{" "}
                <a
                  href={shareUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 underline break-all"
                >
                  {shareUrl}
                </a>
              </div>
              <p className="text-sm text-gray-500 mt-2">
                (Already copied to clipboard)
              </p>
            </div>
            <p className="text-center text-gray-700 mb-4">
              Don’t forget to tag{" "}
              <span className="font-semibold">Noorahealth</span>!
            </p>
            <p className="text-center text-gray-700 mb-6">
              To download or copy caption again, use the below buttons
            </p>
            <div className="flex flex-wrap justify-center gap-3">
              <button
                onClick={() => {
                  downloadLogo();
                  handleClose();
                }}
                className="bg-[#383838] text-white font-medium py-2 px-6 rounded-lg shadow-md"
              >
                Download Tile
              </button>
              <button
                onClick={() => {
                  copyClipboard();
                  handleClose();
                }}
                className="bg-[#383838] text-white font-medium py-2 px-6 rounded-lg shadow-md"
              >
                Copy Caption
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default SubmitPage;
