import React, { useEffect, useState } from "react";
import Balance from "../assets/svg/dashboardSvgs/Balance";
import Bird from "../assets/svg/dashboardSvgs/Bird";
import Captain from "../assets/svg/dashboardSvgs/Captain";
import Rock from "../assets/svg/dashboardSvgs/Rock";
import Cat from "../assets/svg/dashboardSvgs/Cat";
import Dog from "../assets/svg/dashboardSvgs/Dog";
import Elephant from "../assets/svg/dashboardSvgs/Elephant";
import Heartly from "../assets/svg/dashboardSvgs/Heartly";
import Tune from "../assets/svg/dashboardSvgs/Tune";
import { API_Path } from "../API/ApiComment";
import { ApiGet } from "../API/API_data";
import ParliamentChart from "./ParliamentChart";
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_BACKEND_DEV_URL);

const CommunityCount = ({ count }) => {
  const [data, setData] = useState([]);
  const [dots, setDots] = useState({});
  const colors = ["#ED2777", "#00986B", "#00B9EA", "#FAA31B"];

  useEffect(() => {
    getTileData();

    socket.on("dataUpdate", (updatedData) => {
      if (updatedData?.titleWiseCount && updatedData?.dotCounts) {
        setData(updatedData?.titleWiseCount);
        setDots(updatedData?.dotCounts);
      }
    });

    return () => {
      socket.off("dataUpdate");
    };
  }, []);

  const getTileData = async () => {
    try {
      const res = await ApiGet(API_Path.titleDataUrl);
      if (res?.success === true) {
        setData(res?.titleWiseCount);
        setDots(res?.dotCounts);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const iconData = [
    {
      title: "Dependable Rock",
      Component: Elephant,
      count: 0,
      subTitle: "Dependable Rock",
    },

    {
      title: "The hearty helper",
      Component: Heartly,
      count: 0,
      subTitle: "Hearty helper",
    },
    {
      title: "Captain compassion",
      Component: Captain,
      count: 0,
      subTitle: "Captain compassion",
    },
    {
      title: "Motivation retriever",
      Component: Dog,
      count: 0,
      subTitle: "Motivation retriever",
    },
    {
      title: "Catalyst in tune",
      Component: Tune,
      count: 0,
      subTitle: "Catalyst in tune",
    },
    {
      title: "The steadfast ally",
      Component: Rock,
      count: 0,
      subTitle: "Steadfast ally",
    },
    {
      title: "The balancing resolute",
      Component: Balance,
      count: 0,
      subTitle: "Balancing resolute",
    },
    {
      title: "Pragmatic Possum",
      Component: Cat,
      count: 0,
      subTitle: "Pragmatic Possum",
    },
    {
      title: "The care-ier",
      Component: Bird,
      count: 0,
      subTitle: "The care-ier",
    },
  ];

  const mergedData = iconData?.map((iconItem) => {
    const matchingData = data?.find((item) => item._id === iconItem.title);
    return {
      ...iconItem,
      count: matchingData ? matchingData.count : 0,
    };
  });

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const renderColoredCount = (count) => {
    return count
      .toString()
      .split("")
      .map((letter, index) => (
        <span key={index} style={{ color: getRandomColor() }}>
          {letter}
        </span>
      ));
  };

  return (
    <div className="lg:flex lg:w-full lg:justify-between md:flex-col md:justify-center md:items-center">
      <div>
        <h2 className="font-playfair font-medium text-[24px] text-center xl:text-[20px]">
          Caregiving Community strength
        </h2>
        <h2 className="font-poppins font-semibold text-6xl px-8 text-center xl:text-[24px]">
          {count && renderColoredCount(count)}
        </h2>
        <div className="flex justify-between flex-wrap gap-5 xl:gap-3 mt-5 max-w-[350px] xl:max-w-[320px]">
          {/* <div className="grid grid-cols-3 gap-5 xl:gap-3 mt-5"> */}
          {mergedData?.map(({ Component, count, subTitle }, index) => (
            <div
              key={index}
              className="flex flex-col gap-1 justify-center items-center"
            >
              <p
                className={`text-[12px] xl:text-[10px] text-center max-w-20 leading-3`}
              >
                {subTitle}
              </p>
              <div>
                <Component />
              </div>
              <h2
                className={`font-semibold xl:text-[12px] ${
                  subTitle === "Balancing resolute" ||
                  subTitle === "Steadfast ally" ||
                  subTitle === "Captain compassion"
                    ? "text-[#ED2777]"
                    : subTitle === "The care-ier" ||
                      subTitle === "Dependable Rock"
                    ? "text-[#00986B]"
                    : subTitle === "Hearty helper" ||
                      subTitle === "Motivation retriever" ||
                      subTitle === "Catalyst in tune"
                    ? "text-[#FAA31B]"
                    : "text-[#00B9EA]"
                }`}
                // style={{ color: getRandomColor() }}
              >
                {count}
              </h2>
            </div>
          ))}
        </div>
      </div>
      <div>
        {/* <div className="flex justify-between items-center gap-2 mt-2 px-8 xxs:px-0">
          <div className="flex gap-2">
            <div className="bg-[#AAAAAA] w-8 h-8"></div>
            <div className="text-[12px] leading-4">
              <p>Challenging</p>
              <p>{dots?.totalGrayDot}</p>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="bg-[#000000] w-8 h-8"></div>
            <div className="text-[12px] leading-4">
              <p>Fulfilling</p>
              <p>{dots?.totalBlackDot}</p>
            </div>
          </div>
        </div> */}
        <div className="flex justify-center items-center">
          <ParliamentChart dots={dots} />
        </div>
      </div>
    </div>
  );
};

export default CommunityCount;
