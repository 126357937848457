import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";

const FinalImageSec = ({ data }) => {
  const containerRef = useRef(null);
  const isFirstLoad = useRef(true); // Track if it’s the initial load

  useEffect(() => {
    if (isFirstLoad.current && data?.length > 0 && containerRef.current) {
      // Scroll to bottom only on initial load
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
      isFirstLoad.current = false; // Set to false to prevent further scrolling on updates
    }
  }, [data]);

  // useEffect(() => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollTop = containerRef.current.scrollHeight;
  //   }
  // }, [data]);

  return (
    <div
      ref={containerRef}
      className="flex flex-wrap gap-1.5 lg:justify-center overflow-y-auto p-5"
      style={{ maxHeight: "100vh" }}
    >
      {data?.length > 0 && (
        <>
          {data.slice(0, -1).map((val, index) => (
            <img
              key={index}
              src={val.imageLink}
              alt={`Logo ${index}`}
              className="w-20 h-20 rounded-md border border-gray-300 p-2 bg-white flex justify-center items-center"
            />
          ))}
          {isFirstLoad.current ? (
            // Render without animation on initial load
            <img
              key={data.length - 1}
              src={data[data.length - 1]?.imageLink}
              alt={`Logo ${data.length - 1}`}
              className="w-20 h-20 rounded-md cursor-pointer border border-[#383838] p-2 bg-white flex justify-center items-center"
            />
          ) : (
            // Render with animation on subsequent updates
            <motion.img
              key={data.length - 1}
              src={data[data.length - 1]?.imageLink}
              alt={`Logo ${data.length - 1}`}
              className="w-20 h-20 rounded-md cursor-pointer border border-[#383838] p-2 bg-white flex justify-center items-center"
              initial={{ y: 200, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 3 }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FinalImageSec;
