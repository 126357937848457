import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const ParliamentChart = ({ dots }) => {
  const { totalGrayDot = 0, totalBlackDot = 0, totalDots = 1 } = dots;

  const chartRef = useRef(null);

  // Calculate the percentages
  const grayDotPercentage = (totalGrayDot / totalDots) * 100;
  const blackDotPercentage = (totalBlackDot / totalDots) * 100;

  const grayDot = Math.round(grayDotPercentage); // 48
  const blackDot = Math.round(blackDotPercentage);

  useEffect(() => {
    /**
     ** Declare Chart
     */
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);

    /**
     ** Declare Example Dataset
     */

    const data = [
      { value: grayDotPercentage, name: "A" },
      { value: blackDotPercentage, name: "B" },
    ];

    /**
     ** Declare Color Template
     */
    const defaultPalette = ["#AAAAAA", "#000000"];

    /**
     ** Declare Radius of Chart
     */
    const radius = ["30%", "80%"];

    /**
     ** Declare Series
     */
    const parliamentSeries = (() => {
      const sum = data.reduce((sum, cur) => sum + cur.value, 0);
      const angles = [];
      const startAngle = Math.PI;
      let curAngle = startAngle;
      data.forEach((item) => {
        angles.push(curAngle);
        curAngle += (item.value / sum) * Math.PI;
      });
      angles.push(startAngle + Math.PI);

      const parliamentLayout = (
        startAngle,
        endAngle,
        totalAngle,
        r0,
        r1,
        size
      ) => {
        const rowsCount = Math.ceil((r1 - r0) / size);
        const points = [];
        let r = r0;
        for (let i = 0; i < rowsCount; i++) {
          const totalRingSeatsNumber = Math.round((totalAngle * r) / size);
          const newSize = (totalAngle * r) / totalRingSeatsNumber;
          for (
            let k = Math.floor((startAngle * r) / newSize) * newSize + 7;
            k < Math.floor((endAngle * r) / newSize) * newSize - 1e-6;
            k += newSize
          ) {
            const angle = k / r;
            const x = Math.cos(angle) * r;
            const y = Math.sin(angle) * r;
            points.push([x, y]);
          }
          r += size;
        }
        return points;
      };

      return {
        type: "custom",
        id: "distribution",
        data: data,
        coordinateSystem: undefined,
        universalTransition: true,
        animationDurationUpdate: 1000,
        renderItem: function (params, api) {
          const idx = params.dataIndex;
          const viewSize = Math.min(api.getWidth(), api.getHeight());
          const r0 = ((parseFloat(radius[0]) / 100) * viewSize) / 1.6;
          const r1 = ((parseFloat(radius[1]) / 100) * viewSize) / 1.6;
          const cx = api.getWidth() * 0.5;
          const cy = api.getHeight() * 0.7;
          const size = viewSize / 40; // Adjusted size for 400px view
          const points = parliamentLayout(
            angles[idx],
            angles[idx + 1],
            Math.PI,
            r0,
            r1,
            size + 2.5 // Adjust for smaller circles
          );
          return {
            type: "group",
            children: points.map((pt) => ({
              type: "circle",
              autoBatch: true,
              shape: {
                cx: cx + pt[0],
                cy: cy + pt[1],
                r: size / 2,
              },
              style: {
                fill: defaultPalette[idx % defaultPalette.length],
              },
            })),
          };
        },
      };
    })();

    /**
     ** Declare Chart Option
     */
    const option = {
      //   title: {
      //     show: true,
      //     // text: sum,
      //     bottom: "28%",
      //     left: "42%",
      //   },
      series: parliamentSeries,
    };

    /**
     ** Generate Chart by Option
     */
    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [dots, grayDotPercentage, blackDotPercentage]);

  return (
    <div className="relative">
      <div className="absolute top-16 text-[20px] text-[#FAA31B]">
        {grayDot} %
      </div>
      <div className="absolute top-16 right-1 text-[20px] text-[#ED2777]">
        {blackDot} %
      </div>
      <div className="-mb-10">
        <div
          ref={chartRef}
          className="w-[350px] h-[260px] xl:w-[300px] xl:h-[200px] xxs:w-[290px] xxs:h-[280px]"
        ></div>
      </div>
      <h2 className="font-playfair font-medium text-xl px-8 text-center xl:text-[14px] xl:leading-[14px] 2xl:mb-8">
        Highs & Lows <br /> of the caregiver experience
      </h2>
    </div>
  );
};

export default ParliamentChart;
